// import https from 'https'

function getMessage (message) {
  switch (message) {
    case 'This action is unauthorized.':
      return 'Tato akce je neoprávněná.'
    case 'Unauthenticated.':
      return 'Přihlášení vypršelo.'
    case undefined:
      return 'Chyba'
    default:
      return message
  }
}

export default function (context) {
  // if (isDev) { $axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false }) }

  context.$axios.onError((error) => {
    const statusCode = error.response?.status

    context.$notify({
      message: getMessage(error.response?.data?.message),
      timeout: 2500,
      icon: 'fa fa-times',
      type: 'error'
    })

    // Hodíme chybu pouze, pokud status není 401
    if (![401, 419].includes(statusCode)) {
      throw error
    }
  })
  context.$axios.onResponse((response) => {
    if (response?.data?.message) {
      context.$notify({
        message: response?.data?.message,
        timeout: 2500,
        icon: 'fa fa-check',
        type: 'success'
      })
    }
  })
}
