import { set } from 'vue'

export const state = () => ({
  attempt: null,
  completedExam: null,
  filledQuestions: []
})

export const mutations = {
  setAttempt (state, attempt) {
    state.attempt = attempt
  },
  setCompletedExam (state, completedExam) {
    state.completedExam = completedExam
  },
  async setCompletedExamAnswer (state, { answer, sectionIndex, questionIndex }) {
    set(state.completedExam.sections[sectionIndex].questions[questionIndex], 'answers', answer)

    this.$axios.$put(`/api/students/exams/${state.attempt.student_exam_id}/attempts/${state.attempt.id}`, {
      'g-recaptcha-response': await this.$recaptcha.execute('student_exam'),
      serialized_exam_filled: state.completedExam
    }, { loading: false })
  },
  async addCheatingWarning (state) {
    const response = await this.$axios.$put(`/api/students/exams/${state.attempt.student_exam_id}/attempts/${state.attempt.id}`, {
      'g-recaptcha-response': await this.$recaptcha.execute('student_exam'),
      serialized_exam_filled: state.completedExam,
      add_warning: true
    }, { loading: false })

    this.commit('student/setAttempt', response.item)
  },
  addFilledQuestion (state, questionId) {
    if (!state.filledQuestions.includes(questionId)) {
      state.filledQuestions.push(questionId)
    }
  },
  removeFilledQuestion (state, questionId) {
    state.filledQuestions = state.filledQuestions.filter(id => id !== questionId)
  }
}
