export const state = () => ({
  title: null,
  navItems: [],
  sidebarItems: [
    {
      to: '/admin/firmy',
      content: 'Firmy',
      icon: 'file-alt',
      permission: 'companies.index'
    },
    {
      to: '/admin/oddeleni',
      content: 'Oddělení',
      icon: 'users',
      permission: 'settings.tags.index'
    },
    {
      to: '/admin/testy',
      content: 'Testy',
      icon: 'file-alt',
      permission: 'exams.index'
    },
    {
      to: '/admin/studenti',
      content: 'Studenti',
      icon: 'users',
      permission: 'students.index'
    },
    {
      to: '/admin/nastaveni/',
      content: 'Nastavení',
      icon: 'cog',
      permission: 'settings.activities.index',
      navItems: [
        {
          to: '/admin/nastaveni/aktivity',
          content: 'Aktivity',
          permission: 'settings.activities.index'
        },
        {
          to: '/admin/nastaveni/stitky',
          content: 'Štítky',
          permission: 'settings.tags.index'
        },
        {
          to: '/admin/nastaveni/uzivatele',
          content: 'Administrátoři',
          icon: 'user-shield',
          permission: 'users.users.index'
        }
      ]
    }
  ]
})

export const getters = {
  loginRedirectRoute (state) {
    const adminRoles = ['admin', 'kanu-admin']
    if ((state.auth.user.roles || []).some(role => adminRoles.includes(role))) {
      return '/admin/testy'
    }

    return '/nastenka'
  }
}

export const mutations = {
  setTitle (state, title) {
    state.title = title
  }
}
