export default {
  data: vm => ({
    bgColor: vm.$auth.user?.current_company?.bg_color || null
  }),
  head: vm => ({
    style: [
      vm.bgColor
        ? {
            hid: 'custom-style',
            cssText: `
            :root {
            --main-bg-color: ${vm.bgColor} !important;
            }
          `,
            type: 'text/css'
          }
        : undefined
    ].filter(Boolean)
  }),
  watch: {
    '$auth.loggedIn' (newVal) {
      if (newVal) {
        this.bgColor = this.$auth.user?.current_company?.bg_color
      }
    }
  }
}
