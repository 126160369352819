import Vue from 'vue'
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate'
// eslint-disable-next-line camelcase
import { email, integer, max, max_value, min, min_value, numeric, regex, required } from 'vee-validate/dist/rules'
import cs from 'vee-validate/dist/locale/cs.json'
import moment from 'moment/moment'

localize('cs', {
  ...cs,
  messages: {
    ...cs.messages,
    date: 'Pole {_field_} není platné datum',
    before: 'Pole {_field_} nesmí být po {target}',
    after: 'Pole {_field_} nesmí být před {target}',
    digits_between: 'Pole {_field_} musí být dlouhé {min} - {max} číslic'
  }
})

extend('required', required)
extend('numeric', numeric)
extend('email', email)
extend('integer', integer)
extend('min', min)
extend('max', max)
extend('min_value', min_value)
extend('max_value', max_value)
extend('regex', regex)

extend('date', { validate: value => moment(value).isValid() })
extend('before', {
  validate: (value, _a) => {
    const { target } = _a
    if (!target) {
      return true
    }
    return moment(value).isSameOrBefore(moment(target))
  },
  params: [
    {
      name: 'target',
      isTarget: true
    }
  ]
})
extend('after', {
  validate: (value, _a) => {
    const { target } = _a
    if (!target) {
      return true
    }
    return moment(value).isSameOrAfter(moment(target))
  },
  params: [
    {
      name: 'target',
      isTarget: true
    }
  ]
})

extend('digits_between', {
  params: ['min', 'max'],
  validate (value, { min, max }) {
    const length = value.toString().length
    return length >= min && length <= max
  }
})

// Register it globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
