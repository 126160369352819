
import { throttle } from 'lodash'

export default {
  name: 'JbSidebar',
  data: () => ({
    visible: true,
    companies: [],
    companyOptions: [],
    companyLoading: false,
    forbidden_paths_when_no_company: [
      '/admin/studenti',
      '/admin/testy'
    ],
    windowWidth: 0
  }),
  computed: {
    navItems: (vm) => {
      let items = vm.$store.state.sidebarItems
      if (!vm.company) {
        items = items.filter(item => !vm.forbidden_paths_when_no_company.includes(item.to))
      }
      return items || []
    },
    company: {
      get: vm => vm.$auth.user.current_company?.id,
      async set (newVal) {
        await this.$axios.$post('/api/changeCurrentCompany', {
          current_company_id: newVal
        })
        this.$auth.fetchUser()

        if (['/admin/testy', '/admin/firmy', '/admin/oddeleni', '/admin/studenti'].includes(this.$route.path)) {
          this.$root.$emit('refresh')
        } else {
          this.$nuxt.context.redirect('admin/testy')
        }
      }
    }
  },
  watch: {
    windowWidth (newVal) {
      this.visible = newVal >= 992
    }
  },
  async created () {
    await this.getCompanies()
    if (!this.company) {
      for (const forbiddenPath of this.forbidden_paths_when_no_company) {
        if (this.$route.path.includes(forbiddenPath)) {
          this.$nuxt.context.redirect('admin/firmy')
          return
        }
      }
    }
  },
  mounted () {
    this.handleResize = throttle(this.handleResize.bind(this), 300)
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    async getCompanies () {
      this.companyLoading = true
      const response = await this.$axios.$get('/api/companies')
        .catch((error) => {
          return this.$nuxt.context.error({
            statusCode: error.response?.status || 500,
            message: error.response?.statusText || 'Internal Server Error'
          })
        })

      this.companyOptions = response.data.map(({ id, name }) => ({ id, name }))

      this.companyLoading = false
    },
    handleResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
