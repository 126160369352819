export default (ctx, inject) => {
  inject('tables', {
    department: () => ([
      {
        key: 'name',
        label: 'Název',
        sortable: false,
        width: '400px'
      },
      {
        key: 'actions',
        label: 'Akce',
        class: 'text-center',
        width: '200px'
      }
    ]),
    company: () => ([
      {
        key: 'name',
        label: 'Název',
        sortable: false,
        width: '400px'
      },
      {
        key: 'domain',
        label: 'Doména',
        sortable: false,
        width: '200px'
      },
      {
        key: 'bg_color',
        label: 'Barva rozhraní',
        sortable: false,
        width: '200px'
      },
      {
        key: 'actions',
        label: 'Akce',
        class: 'text-center',
        width: '200px'
      }
    ]),
    exam: () => ([
      {
        key: 'title',
        label: 'Název',
        sortable: false,
        width: '400px'
      },
      {
        key: 'required_percent',
        label: 'Náročnost',
        sortable: false,
        width: '400px'
      },
      {
        key: 'created_at',
        label: 'Vytvořeno',
        sortable: false,
        width: '200px'
      },
      {
        key: 'actions',
        label: 'Akce',
        class: 'text-center',
        width: '200px'
      }
    ]),
    examReport: () => ([
      {
        key: 'date_to',
        label: 'Do data',
        sortable: false,
        width: '200'
      },
      {
        key: 'actions',
        label: 'Akce',
        class: 'text-center',
        width: '200px'
      }
    ]),
    examSection: () => ([
      {
        key: 'title',
        label: 'Název',
        sortable: false,
        width: '400px'
      },
      {
        key: 'type',
        label: 'Typ sekce',
        sortable: false,
        width: '200px'
      },
      {
        key: 'questions_count',
        label: 'Otázek',
        sortable: false,
        width: '100px'
      },
      {
        key: 'actions',
        label: 'Akce',
        class: 'text-center',
        width: '200px'
      }
    ]),
    examStudent: () => ([
      {
        key: 'name',
        label: 'Jméno a příjmení',
        sortable: false,
        width: '400px'
      },
      {
        key: 'email',
        label: 'Přihlášení',
        sortable: false,
        width: '400px'
      },
      {
        key: 'attempts',
        label: 'Absolvovaných pokusů',
        sortable: false,
        width: '400px'
      },
      {
        key: 'max_attempts',
        label: 'Max pokusů',
        sortable: false,
        width: '400px'
      },
      {
        key: 'last_attempt',
        label: 'Hodnocení',
        sortable: false,
        width: '400px'
      },
      {
        key: 'actions',
        label: 'Akce',
        class: 'text-center',
        width: '200px'
      }
    ]),
    examQuestion: () => ([
      {
        key: 'title',
        label: 'Název',
        sortable: false,
        width: '400px'
      },
      {
        key: 'type',
        label: 'Typ otázky',
        sortable: false,
        width: '200px'
      },
      {
        key: 'answer_type',
        label: 'Typ odpovědi',
        sortable: false,
        width: '200px'
      },
      {
        key: 'answers_count',
        label: 'Odpovědí',
        sortable: false,
        width: '100px'
      },
      {
        key: 'actions',
        label: 'Akce',
        class: 'text-center',
        width: '200px'
      }
    ]),
    student: () => ([
      {
        key: 'name',
        label: 'Jméno a příjmení',
        sortable: false,
        width: '500px'
      },
      {
        key: 'personal_number',
        label: 'Osobní číslo',
        sortable: false,
        width: '200px'
      },
      {
        key: 'departments',
        label: 'Oddělení',
        sortable: false,
        width: '400px'
      },
      {
        key: 'contact',
        label: 'Kontakt',
        sortable: false,
        width: '400px'
      },
      {
        key: 'note',
        label: 'Poznámka',
        sortable: false,
        width: '400px'
      },
      {
        key: 'actions',
        label: 'Akce',
        class: 'text-center',
        width: '200px'
      }
    ]),
    activities: () => ([
      {
        key: 'log_name',
        label: 'Log',
        sortable: false,
        width: '400px'
      },
      {
        key: 'description',
        label: 'Popis',
        sortable: false,
        width: '400px'
      },
      {
        key: 'properties',
        label: 'Změna',
        sortable: false,
        width: '400px'
      }
    ]),
    tags: () => ([
      {
        key: 'name',
        label: 'Název',
        sortable: false,
        width: '400px'
      },
      {
        key: 'type',
        label: 'Typ',
        sortable: false,
        width: '400px'
      },
      {
        key: 'actions',
        label: 'Akce',
        class: 'text-center',
        width: '200px'
      }
    ])
  })
}
